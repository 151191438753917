import React from 'react'
const IndexPage = () => {
  return (
    <div>
      <h1>This site is under construction.</h1>
      <h2>Please come back soon!</h2>
    </div>
  )
}

export default IndexPage
